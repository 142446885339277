<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        :label="$t('report_type')"
                                                        clearable
                                                        single-line
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-autocomplete v-model="serverParams.ReportCat"
                                                        :items="ReportCats"
                                                        :label="$t('pilgrimage_name')"
                                                        clearable
                                                        single-line
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="value"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-autocomplete v-model="serverParams.isIndian"
                                                        :items="ReportInclusionCats"
                                                        :label="$t('inclusion')"
                                                        clearable
                                                        single-line
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-select v-if="!reportIsCountryWise"
                                                  :items="customYear"
                                                  :label="$t('from_date')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                        <v-select v-if="reportIsCountryWise"
                                                  :items="customYear"
                                                  :label="$t('select_year')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-select v-if="!reportIsCountryWise"
                                                  :items="customYear"
                                                  :label="$t('to_date')"
                                                  v-model="serverParams.ToDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                    </v-col>
                                        <!--<v-col class="d-flex" cols="12" sm="2">
            <v-menu v-model="snackbar.menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="serverParams.FromDate"
                                  :label="$t('from_date')"
                                  clearable
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="serverParams.FromDate">

                </v-date-picker>
            </v-menu>
        </v-col>-->
                                        <!--<v-col class="d-flex" cols="12" sm="2" v-if="!reportIsCountryWise">
            <v-menu v-model="snackbar.menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="serverParams.ToDate"
                                  :label="$t('to_date')"
                                  clearable
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="serverParams.ToDate">

                </v-date-picker>
            </v-menu>
        </v-col>-->

                                        <v-col class="d-flex text-center" cols="12" sm="12">
                                            <v-spacer></v-spacer>
                                            <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                                <v-icon dark>search</v-icon>
                                                {{$t('search')}}
                                            </v-btn>
                                            <download-excel :fields="excelData.json_fields"
                                                            :fetch="onDownload"
                                                            worksheet="My Worksheet">
                                                <v-btn small class="success mx-4">
                                                    <v-icon>get_app</v-icon>
                                                    {{$t('download_to_excel')}}
                                                </v-btn>
                                            </download-excel>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
        </span>-->
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import downloadExcel from "vue-json-excel";
    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: { downloadExcel },
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsCountryWise() {
                return this.serverParams.ReportType === 'countryWise';
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('pilgrimage'),
                        exact: true,
                        to: '/reportModules/reportPilgrimage'
                    },
                    {
                        text: this.$t('modules_name.monthly_yearly_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            },
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            async getFilterData() {
                try {
                    const {data} = await axios.get('Pilgrimage/DDLPilGrimageListAsync')
                    this.ReportCats = data
                } catch (e) {
                    console.log(e)
                }
            },
            goBackToReportDashboard() {
                this.$emit('goBackToReportDashboard')
            },
            goBackToReportSelection() {
                this.$emit('goBackToReportSelection')
            },
            formResponse(data) {
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {

                if (this.serverParams.ReportType == "countryWise") {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        FromDate: this.serverParams.FromDate,
                        FilterBy: this.serverParams.ReportCat,
                        isIndian: this.serverParams.isIndian,
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    await axios({
                        url: 'Pilgrimage/DownloadPilGrimageByCountryWiseReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'PilGrimageByCountryWiseReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        FilterBy: this.serverParams.ReportCat,
                        FromDate: this.serverParams.FromDate,
                        ToDate: this.serverParams.ToDate,
                        isIndian: this.serverParams.isIndian,
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    await axios({
                        url: 'Pilgrimage/DownlaodPilGrimageByMonthReport',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        console.log('pp', response)
                        this.excelData.json_fields['Month'] = 'Month'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                    return this.excelData.json_data
                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'PilgrimageFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },

            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                if (this.reportIsCountryWise) {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        FromDate: this.serverParams.FromDate + '-01-01',
                        FilterBy: this.serverParams.ReportCat,
                        isIndian: this.serverParams.isIndian,
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    axios.post('Pilgrimage/GetPilGrimageByCountryWiseReport', param)
                        .then(response => {
                            this.rows = response.data;
                            //this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns.push(
                                {
                                    label: 'country',
                                    field: 'country'
                                },
                                {
                                    label: 'january',
                                    field: 'january'
                                },
                                {
                                    label: 'february',
                                    field: 'february'
                                }, {
                                label: 'march',
                                field: 'march'
                            }, {
                                label: 'april',
                                field: 'april'
                            }, {
                                label: 'may',
                                field: 'may'
                            }, {
                                label: 'june',
                                field: 'june'
                            }, {
                                label: 'july',
                                field: 'july'
                            }, {
                                label: 'august',
                                field: 'august'
                            }, {
                                label: 'september',
                                field: 'september'
                            }, {
                                label: 'october',
                                field: 'october'
                            }, {
                                label: 'november',
                                field: 'november'
                            }, {
                                label: 'december',
                                field: 'december'
                            }, {
                                label: 'total',
                                field: 'total'
                                })
                            this.snackbar.loading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                } else {
                    let param = {
                        /* PageSize: this.serverParams.perPage,
                         PageNo: this.serverParams.page,*/
                        FilterBy: this.serverParams.ReportCat,
                        FromDate: this.serverParams.FromDate + '-01-01',
                        ToDate: this.serverParams.ToDate + '-12-31',
                        isIndian: this.serverParams.isIndian,
                        /* OrderType: this.serverParams.sort[0].type,
                         OrderBy: this.serverParams.sort[0].field,*/
                        /* FromDate: this.serverParams.FromDate,
                         ToDate: this.serverParams.ToDate,
                         ReportType: this.serverParams.ReportType,*/
                    };
                    axios.post('Pilgrimage/GetPilGrimageByMonthReport', param)
                        .then(response => {
                            this.rows = response.data.dataList;
                            this.totalRecords = response.data.totalCount;
                            this.columns = []
                            this.columns = response.data.header
                            this.columns.unshift({
                                label: 'month',
                                field: 'Month'
                            })
                            this.columns.push({
                                label: 'total',
                                field: 'Total'
                            })
                            this.snackbar.loading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }


            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.getFilterData()
            this.loadItems()
        },
        data() {
            return {
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [],
                rows: [],
                ReportTypes: [
                    { id: 'monthWise', value: this.$t('year_month_wise') },
                    { id: 'countryWise', value: this.$t('country_wise') },
                ],
                ReportInclusionCats: [
                    { id: 'indian', value: this.$t('only_indian') },
                    { id: 'exclude', value: this.$t('indian_exclusive') },
                    { id: 'include', value: this.$t('indian_inclusive') },
                ],
                ReportCats: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: "",
                    isIndian: "",
                    ReportCat: "",
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
